import React from "react";

// Tabs
import Cookie from "./Tabs/myPreference";
import Communication from "./Tabs/myData";

const About = () => {
  return (
    <>
      <div classNameName="pt-1">
        <ul
          className="nav nav-tabs nav-pills mb-3 p-3"
          id="pills-tab"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="pills-Cookie-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-Cookie"
              type="button"
              role="tab"
              aria-controls="pills-Cookie"
              aria-selected="true"
            >
              Own consents and communication
            </button>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-Cookie"
            role="tabpanel"
            aria-labelledby="pills-Cookie-tab"
          >
            <Cookie />
          </div>
          <div
            className="tab-pane fade"
            id="pills-Communication"
            role="tabpanel"
            aria-labelledby="pills-Communication-tab"
          >
            <Communication />
          </div>
        </div>
      </div>
    </>
  );
};

export default About;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./css/VentasPro-Login.css";
import logo from "../../assets/images/LocalTapiola.png";

const Login = () => {
  const navigate = useNavigate();
  const [newUser, setnewUser] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    // console.log(e.target.name)
    setnewUser({
      ...newUser,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    localStorage.setItem("token", newUser.email);
    function customCb(data) {
      console.log("call back", data);
      navigate("/");
    }
    window.gravito.firstParty.attachMatchOnId(newUser.email, customCb);
  };

  return (
    <div id="main">
      <div
        className="text-center px-3 py-5 col-10 col-sm-7 col-lg-4 align-self-center"
        id="info"
      >
        <img
          src={
            "https://gravitocdn.blob.core.windows.net/logos/Gravito logo new.png"
          }
          alt="logo"
          id="Gravito-logo"
          width="200"
        />
        <h3 className="text-center fs-3">Welcome</h3>
        <p className="text-center fs-6">Please login here to continue</p>
        <form className="text-start" id="form-login">
          <div className="mb-3">
            {/* <label
              className="form-label fs-5 fw-semibold"
              id="lbl-usuario"
              htmlFor="txt-usuario"
            >
              Username
            </label> */}
            <input
              className="form-control fs-5"
              type="email"
              id="email"
              placeholder="Enter Email"
              inputMode="email"
              name="email"
              style={{}}
              onChange={(e) => handleInputChange(e)}
              autoFocus
            />
          </div>
          <br />
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary fs-5 fw-semibold text-white "
              id="btn-sesion"
              type="button"
              onClick={handleSubmit}
            >
              Login
            </button>
          </div>
        </form>
      </div>
      <div>
        <p
          className="gravito-openpref-btn"
          onClick={() =>
            window.gravitoLightCMP.openPreferences &&
            window.gravitoLightCMP.openPreferences()
          }
        >
          Open Preferences
        </p>
      </div>
    </div>
  );
};

export default Login;

import React from "react";
import { useEffect, useState } from "react";
import MyData from "./myData";

const CookieSection = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      if (
        window.gravito.state &&
        window.gravito.state._state.isLightCMPRendered
      ) {
        setLoading(false);
      } else {
        window.gravito.state.subscribe(
          () => {
            setLoading(false);
          },
          (current, old) => {
            return current.isLightCMPRendered !== old.isLightCMPRendered;
          }
        );
      }
    }, 2000);
  }, []);
  const getConsentValue = (id) => {
    return window.gravitoLightCMP.consentHandler.getConsentValue(id);
  };
  const updateConsent = () => {
    try {
      window.gravitoLightCMP.useractionHandler.saveSelected();
      window.gravitoLightCMP.eventsHandler.cmpevent("layer2:opt-in:selected");
      alert("Consent Updated successfully");
    } catch (error) {
      console.error("error occured while updating consent", error);
    }
  };
  const changeConsent = (e, id) => {
    let value = e.target.checked;
    window.gravitoLightCMP.consentHandler.ConsentById(id, value);
  };
  return (
    <>
      {loading ? (
        <div>
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="accordion w-80 p-3" id="accordionExample">
            {window.gravitoCMPConfig &&
              window.gravitoCMPConfig.core.consents.map((item, i) => (
                <div key={i} className="accordion-item">
                  <div
                    className="d-flex align-items-center accordion-header pe-3"
                    id="headingOne"
                  >
                    <button
                      className="accordion-button collapsed accordion-custom-override "
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse-${item.id}`}
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      {item.name}
                    </button>
                    {item.isConsentable && (
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          // checked={getConsentValue(item.id)}
                          defaultChecked={getConsentValue(item.id)}
                          id="flexCheckDefault"
                          onChange={(e) => changeConsent(e, item.id)}
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckDefault"
                        >
                          Accept
                        </label>
                      </div>
                    )}
                  </div>
                  <div
                    id={`collapse-${item.id}`}
                    className="accordion-collapse collapse "
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: item.description }}
                      className="accordion-body text-start"
                    ></div>
                  </div>
                </div>
              ))}
          </div>
          <div className="d-flex justify-content-end">
            <button
              onClick={updateConsent}
              type="button"
              className="btn btn-primary mx-2"
            >
              Save
            </button>
            {/* <button
              onClick={() => window.location.reload()}
              type="button"
              className="btn btn-primary mx-2"
            >
              virkistää
            </button> */}
          </div>
        </>
      )}
    </>
  );
};
const Communication = () => {
  const [loading, setLoading] = useState(true);
  const [commConsents, setCommConsents] = useState([
    {
      id: 1,
      name: "E-mail",
      description: "Consent to be contacted by e-mail",
      consent: false,
    },
    {
      id: 2,
      name: "Phone",
      description: "Consent to be contacted by phone",
      consent: false,
    },
    {
      id: 3,
      name: "Text message (SMS)",
      description: "Consent to contact by text message",
      consent: false,
    },
  ]);

  useEffect(() => {
    setTimeout(() => {
      window.gravito.firstParty.getProfile(
        (d) => {
          if (d) {
            let consents = JSON.parse(d).c;
            let consentMod = commConsents.map((ct) => {
              return {
                ...ct,
                consent: getConsentValue(ct.id, consents),
              };
            });
            setCommConsents(consentMod);
            setLoading(false);
          }
        },
        (error) => {
          console.error("error while fetching profile", error);
          setLoading(false);
        }
      );
    }, 2000);
  }, []);

  const getConsentValue = (id, data) => {
    if (data.length === 0) {
      return false;
    }
    let consentItem = data.find((c) => parseInt(c.i) === id);
    return consentItem ? consentItem.s : false;
  };

  const updateConsent = () => {
    try {
      let model = commConsents.map((comC) => {
        return {
          i: comC.id,
          c: comC.name,
          s: comC.consent,
        };
      });
      function callback() {
        alert("consents updated");
      }
      window.gravito.firstParty.postConsents(model, callback);
    } catch (error) {}
  };
  const changeConsent = (e, id) => {
    debugger;
    let value = e.target.checked;
    let index = commConsents.findIndex((c) => c.id === id);
    commConsents[index].consent = value;
    setCommConsents([...commConsents]);
  };
  return (
    <>
      {loading ? (
        <div>
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="accordion w-80 p-3" id="accordionExample">
            {commConsents &&
              commConsents.map((item, i) => (
                <div key={i} className="accordion-item">
                  <div
                    className="d-flex align-items-center accordion-header pe-3"
                    id="headingOne"
                  >
                    <button
                      className="accordion-button collapsed accordion-custom-override "
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse-${item.id}`}
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      {item.name}
                    </button>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        checked={item.consent}
                        id="flexCheckDefault"
                        onChange={(e) => changeConsent(e, item.id)}
                      />
                      <label
                        className="form-check-label"
                        for="flexCheckDefault"
                      >
                        Accept
                      </label>
                    </div>
                  </div>
                  <div
                    id={`collapse-${item.id}`}
                    className="accordion-collapse collapse "
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body text-start">
                      {item.description}
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="d-flex justify-content-end">
            <button
              onClick={updateConsent}
              type="button"
              className="btn btn-primary mx-2"
            >
              Save
            </button>
          </div>
        </>
      )}
    </>
  );
};
const MyPreferences = () => {
  return (
    <>
      <div className="mb-3">
        <p className="bold text-start">
          <h5>Cookie Consent</h5>
        </p>
        <hr />
        <CookieSection />
        <p className="bold text-start">
          <h5>Communication</h5>
        </p>
        <hr />
        <Communication />
        <hr />
        <MyData />
      </div>
    </>
  );
};
export default MyPreferences;

import React from "react";
import { useEffect, useState } from "react";

const MyData = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const getData = () => {
    setLoading(true);

    try {
      window.gravito.firstParty.getProfile((d) => {
        setData(d);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const deleteData = () => {
    try {
      setLoading(true);

      window.gravito.firstParty.attachMatchOnId("delete", (d) => {
        setData(d);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <>
      <div className="d-flex flex-column">
        <div className="d-flex">
          <button
            onClick={getData}
            type="button"
            className="btn btn-primary mx-2"
          >
            Own data
          </button>
          <button
            onClick={deleteData}
            type="button"
            className="btn btn-primary mx-2"
          >
            Data deletion
          </button>
        </div>
        <div className="d-flex justify-content-start mt-3">
          {loading ? (
            <div>
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <textarea
              style={{ height: "250px" }}
              name="current_profile_status"
              id="gravito_current_profile_status"
              cols="80"
              rows="100"
              value={data}
              disabled
              placeholder="Type Here..."
            ></textarea>
          )}
        </div>
      </div>
    </>
  );
};
export default MyData;
